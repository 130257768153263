// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { generatePath } from 'react-router';
import { gql } from '@apollo/client';
import { api } from 'jsw';
import pageData from '../helpers/pageData';
import apolloClient from './apolloClient';

const asyncElement = (moduleLoader, ...propsLoaders) => async props => {
    const [loadedModule, { redirect, ...loadedProps }] = await Promise.all([
        moduleLoader(props),
        Promise.all(propsLoaders.map(propsLoader => propsLoader(props)))
            .then(loadedProps => loadedProps.reduce((prev, curr) => ({ ...prev, ...curr }), props)),
    ]);

    if (redirect) {
        return { redirect };
    }

    const Component = loadedModule.default || loadedModule;
    return (
        <Component {...loadedProps} />
    );
};

const createPageDataLoader = dataPath => async ({ url, params }) => {
    const { isInitial, ...data } = pageData();
    const { redirect, graphqlQueries = [], ...newData } = isInitial ? data : await api.get((dataPath ? generatePath(dataPath, params) : url) + window.location.search);

    if (redirect) {
        return { redirect };
    }

    graphqlQueries.forEach(({ query, ...options }) => {
        try {
            apolloClient.writeQuery({
                query: gql(query),
                ...options,
            });
        } catch {}
    });

    pageData(newData);

    return newData;
};

const createGraphqlLoader = (query, variables) => async () => {
    const { isInitial, status, ...data } = pageData();
    pageData({ ...data, status: isInitial ? status : [] });

    try {
        const { data } = await apolloClient.query({ query, variables });
        return data;
    } catch {
        return {
            redirect: '/',
        };
    }
};

export default [
    {
        path: ['/login_up.php', '/login_up.php3', '/login'],
        dataPath: '/login_up.php',
        element: asyncElement(
            () => import(/* webpackMode: "eager" */'./components/Login'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/get_password.php',
        element: asyncElement(
            () => import(/* webpackMode: "eager" */'./components/GetPassword'),
            createPageDataLoader('/get_password.php'),
        ),
    },
    {
        path: '/ch_pass_by_secret.php',
        element: asyncElement(
            () => import(/* webpackMode: "eager" */'./components/RestorePassword'),
            createPageDataLoader('/ch_pass_by_secret.php'),
        ),
    },
    {
        path: '/admin/setup/progress',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/setup/progress" */'views/admin/setup/progress'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/setup',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/setup/index" */'views/admin/setup/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/home',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/home/admin" */'views/admin/home/admin'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/app',
        exact: true,
        element: createPageDataLoader(),
    },
    {
        path: '/admin/app/info/id/:id',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/app/info" */'views/admin/app/info'),
            createPageDataLoader('/admin/app/info/id/:id'),
        ),
    },
    {
        path: '/admin/app/usage',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/app/usage" */'views/admin/app/usage'),
            createPageDataLoader('/admin/app/usage'),
        ),
    },
    {
        path: '/admin/app/uploaded',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/app/uploaded" */'views/admin/app/uploaded'),
            createPageDataLoader('/admin/app/uploaded'),
        ),
    },
    {
        path: ['/admin/custom-buttons/list', '/smb/custom-buttons/list'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/custom-buttons/list" */'views/admin/custom-buttons/list'),
            createPageDataLoader('/admin/custom-buttons/list'),
        ),
    },
    {
        path: '/admin/ftp-sessions/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/ftp-sessions/list" */'views/admin/ftp-sessions/list'),
            createPageDataLoader('/admin/ftp-sessions/list'),
        ),
    },
    {
        path: '/admin/php-handler/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/php-handler/list" */'views/admin/php-handler/list'),
            createPageDataLoader('/admin/php-handler/list'),
        ),
    },
    {
        path: ['/admin/scheduler', '/smb/scheduler'],
        exact: true,
        element: createPageDataLoader(),
    },
    {
        path: ['/admin/scheduler/tasks-list', '/smb/scheduler/tasks-list'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/default/scheduler/list" */'views/default/scheduler/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/admin/domain/list', '/admin/domain/add-domain'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/domain/list" */'components/pages/DomainsPage'),
            createPageDataLoader('/admin/domain/list'),
        ),
    },
    {
        path: ['/admin/customer/list', '/admin/customer/create'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/customer/list" */'components/pages/CustomersPage'),
            createPageDataLoader('/admin/customer/list'),
        ),
    },
    {
        path: '/admin/customer/overview/id/:id',
        element: createPageDataLoader(),
    },
    {
        path: ['/admin/customer/domains/id/:id', '/admin/customer/add-domain/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/customer/domains" */'components/pages/CustomerDomainsPage'),
            createPageDataLoader('/admin/customer/domains/id/:id'),
        ),
    },
    {
        path: ['/admin/customer/subscriptions/id/:id', '/admin/customer/new-subscription/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/customer/subscriptions" */'components/pages/CustomerSubscriptionsPage'),
            createPageDataLoader('/admin/customer/subscriptions/id/:id'),
        ),
    },
    {
        path: '/admin/reseller/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/reseller/list" */'components/pages/ResellersPage'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/reseller/overview/id/:id',
        element: createPageDataLoader(),
    },
    {
        path: ['/admin/reseller/customers/id/:id', '/admin/reseller/new-customer/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/reseller/customers" */'components/pages/ResellerCustomersPage'),
            createPageDataLoader('/admin/reseller/customers/id/:id'),
        ),
    },
    {
        path: ['/admin/reseller/domains/id/:id', '/admin/reseller/add-domain/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/reseller/domains" */'components/pages/ResellerDomainsPage'),
            createPageDataLoader('/admin/reseller/domains/id/:id'),
        ),
    },
    {
        path: ['/admin/reseller/subscriptions/id/:id', '/admin/reseller/new-subscription/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/reseller/subscriptions" */'components/pages/ResellerSubscriptionsPage'),
            createPageDataLoader('/admin/reseller/subscriptions/id/:id'),
        ),
    },
    {
        path: '/admin/server/tools',
        element: asyncElement(() => import(/* webpackChunkName: "views/admin/server/tools" */'views/admin/server/tools'), createPageDataLoader()),
    },
    {
        path: '/admin/server/preview-domain-settings',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/server/preview-domain-settings"*/'views/admin/server/preview-domain-settings'),
            createPageDataLoader(),
        ),
    },

    {
        path: '/admin/services/list',
        element: asyncElement(() => import(/* webpackChunkName: "views/admin/services/list" */'views/admin/services/list'), createPageDataLoader()),
    },
    {
        path: '/admin/sessions/list',
        element: asyncElement(() => import(/* webpackChunkName: "views/admin/sessions/list" */'views/admin/sessions/list'), createPageDataLoader()),
    },
    {
        path: ['/admin/subscription/list', '/admin/subscription/create'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/subscription/list" */'components/pages/SubscriptionsPage'),
            createPageDataLoader('/admin/subscription/list'),
        ),
    },
    {
        path: ['/admin/subscription/login/all/:all/id/:id/', '/admin/subscription/login/id/:id/'],
        element: createPageDataLoader(),
    },
    {
        path: '/admin/subscription/overview/id/:id/',
        element: createPageDataLoader(),
    },
    {
        path: ['/admin/help/tutorials', '/smb/help/tutorials'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/default/help/tutorials" */'views/default/help/tutorials'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/file-manager/list', '/smb/file-manager/list/domainId/:domainId'],
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/file-manager/list" */'views/smb/file-manager/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/file-manager',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/file-manager/index" */'views/smb/file-manager/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/my-service',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/my-service/index" */'views/smb/my-service/index'),
            createPageDataLoader('/smb/my-service'),
        ),
    },
    {
        path: ['/smb/email-address', '/smb/email-address/index', '/smb/email-address/index/domainId/:domainId'],
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/email-address/index" */'views/smb/email-address/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/email-address/list', '/smb/email-address/list/domainId/:domainId'],
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/email-address/list" */'views/smb/email-address/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/mail-settings/list',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/mail-settings/list" */'views/smb/mail-settings/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/account', '/smb/account/index'],
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/account/index" */'views/smb/account/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/account/show/id/:id',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/account/show" */'views/smb/account/show'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/user/index'],
        exact: true,
        element: createPageDataLoader(),
    },
    {
        path: ['/smb/user/list'],
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/user/list" */'components/pages/UsersPage'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/web/setup', '/smb/web/add-domain/create-webspace/true'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/setup" */'views/smb/web/setup'),
            createPageDataLoader('/smb/web/setup'),
        ),
    },
    {
        path: ['/smb/web/view', '/smb/web/add-domain'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/view" */'views/smb/web/view'),
            createPageDataLoader('/smb/web/view'),
        ),
    },
    {
        path: '/smb/web/overview/id/:id/type/:type',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/view" */'views/smb/web/view'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/web/php-settings/id/:id',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/php-settings" */'views/smb/web/php-settings'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/statistics/details',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/statistics/details" */'views/smb/statistics/details'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/statistics/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/statistics/details" */'views/smb/statistics/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/statistics',
        exact: true,
        element: createPageDataLoader(),
    },
    {
        path: ['/smb/account/switch/all/:all/id/:id', '/smb/account/switch/id/:id'],
        element: createPageDataLoader(),
    },
    {
        path: '/smb/database',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/database/index" */'views/smb/database/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/database/list', '/smb/database/list/domainId/:domainId'],
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/database/list" */'views/smb/database/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/database/users-list/domainId/:domainId',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/database/users-list" */'views/smb/database/users-list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/task-manager',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/task-manager/index" */'views/admin/task-manager/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/dns/index',
        exact: true,
        element: createPageDataLoader(),
    },
    {
        path: '/admin/dns/records',
        exact: true,
        element: createPageDataLoader(),
    },
    {
        path: '/admin/dns/acl-records',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/dns/acl-records" */'views/admin/dns/acl-records'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/dns/add-acl-record',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/dns/add-acl-record" */'views/admin/dns/add-acl-record'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/index/id/:id/type/:type(domain|alias)',
        exact: true,
        element: createPageDataLoader(),
    },
    {
        path: '/smb/dns-zone/records-list/id/:id/type/:type(domain|alias)',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/records-list" */'views/smb/dns-zone/records-list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/soa-record/id/:id/type/:type(domain|alias)',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/soa-record" */'views/smb/dns-zone/soa-record'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/acl-records/id/:id/type/:type(domain|alias)',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/acl-records" */'views/smb/dns-zone/acl-records'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/add-acl-record/id/:id/type/:type(domain|alias)',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/add-acl-record" */'views/smb/dns-zone/add-acl-record'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/cp/settings-ui/edit-interface-view',
        exact: true,
        element: async props => {
            const { data: { viewer, mode } } = await apolloClient.query({ query: gql`
                query {
                    viewer {
                        login
                        type
                    }
                    mode {
                        isViewSwitcherAllowed
                    }
                }
            ` });
            if (viewer.type !== 'ADMIN' || !mode.isViewSwitcherAllowed) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/admin/settings-ui/edit-interface-view" */'views/admin/settings-ui/edit-interface-view'),
                // createPageDataLoader(),
                createGraphqlLoader(require('queries/EditInterfaceViewPage.graphql')),
            )(props);
        },
    },
    {
        path: '/admin/settings-ui/list-languages',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/settings-ui/list-languages" */'views/admin/settings-ui/list-languages'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/settings-ui',
        exact: true,
        element: createPageDataLoader(),
    },
    {
        path: '/admin/database/settings',
        exact: true,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/database/settings"*/'views/admin/database/settings'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/cp/about',
        element: async props => {
            const { data: { viewer } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                        }
                    }
                ` });
            if (viewer.type !== 'ADMIN') {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/admin/about/index" */'views/admin/about/index'),
                createGraphqlLoader(require('queries/AboutPage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/server/db',
        exact: true,
        element: async props => {
            const { data: { viewer } } = await apolloClient.query({
                query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDb
                            }
                        }
                    }
                `,
            });
            if (viewer.type !== 'ADMIN' || !viewer.permissions.manageServerDb) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServersPage'),
                createGraphqlLoader(require('queries/DatabaseServersPage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/server/db/:id/databases',
        exact: true,
        element: async props => {
            const { data: { viewer, databaseServer } } = await apolloClient.query({
                query: gql`
                    query ($id: ID!) {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDb
                            }
                        }
                        databaseServer: node(id: $id) {
                            id
                        }
                    }
                `,
                variables: {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                },
                errorPolicy: 'ignore',
            });
            if (viewer.type !== 'ADMIN' || !viewer.permissions.manageServerDb || !databaseServer) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerDatabasesPage'),
                createGraphqlLoader(require('queries/DatabaseServerDatabasesPage.graphql'), {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                }),
            )(props);
        },
    },
    {
        path: '/cp/server/db/:id/change-password',
        exact: true,
        element: async props => {
            const { data: { viewer, os, mode, databaseServer } } = await apolloClient.query({
                query: gql`
                    query ($id: ID!) {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDb
                            }
                        }
                        os {
                            isWindows
                        }
                        mode {
                            isDemo
                        }
                        databaseServer: node(id: $id) {
                            id
                        }
                    }
                `,
                variables: {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                },
                errorPolicy: 'ignore',
            });
            if (viewer.type !== 'ADMIN' || !viewer.permissions.manageServerDb || !os.isWindows || mode.isDemo || !databaseServer) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerChangePasswordPage'),
                createGraphqlLoader(gql`
                    query ($id: ID!) {
                        databaseServer: node(id: $id) {
                            id
                            ... on DatabaseServer {
                                name
                            }
                        }
                    }
                `, {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                }),
            )(props);
        },
    },
    {
        path: '/cp/websites-diagnostic',
        exact: true,
        element: async props => {
            const { data: { viewer, config } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                        }
                        config {
                            websitesDiagnostic {
                                enabled
                            }
                        }
                    }
                ` });
            if (viewer.type !== 'ADMIN' || !config.websitesDiagnostic.enabled) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/admin/websites-diagnostic/index" */'components/pages/WebsitesDiagnostic'),
                createGraphqlLoader(require('queries/WebsitesDiagnosticPage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp',
        element: asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound')),
    },
    {
        path: '/smb/web/web-server-settings/id/:id',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/web-server-settings" */'views/smb/web/web-server-settings'),
            createPageDataLoader(),
        ),
    },
];
