// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { gql } from '@apollo/client';
import apolloClient from 'plesk/apolloClient';

const PASSWORD_ESTIMATOR = gql`
    query Password($password: String!) {
        estimate(password: $password) {
            level
            suggestions
        }
    }
`;

export default {
    estimatePassword: async value => {
        const response = await apolloClient.query({
            query: PASSWORD_ESTIMATOR,
            variables: { password: value },
        });
        const { data: { estimate: { level, suggestions } } } = response;

        return {
            password: value,
            level,
            percentage: 20 + (level * 20),
            suggestions,
        };
    },
};
