// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

export const createHtmlElement = (tagName, attrs = {}) => {
    const el = document.createElement(tagName);

    Object.keys(attrs).forEach(name => {
        el.setAttribute(name, attrs[name]);
    });

    return el;
};
