// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import ce from './createElement';
import { DoubleListSelector } from './double-list-selector';
import render from './render';
import escapeHtml from './escapeHtml';

import './enhanced-double-list-selector.less';

export class EnhancedDoubleListSelector extends DoubleListSelector {
    _initComponentElement() {
        this._componentElement = document.getElementById(this._applyTargetId);
        this._componentElement.classList.add('edls');

        this._updateComponentElement(
            ce('.edls-wrap',
                ce('.edls-box.edls-box-first',
                    ce('.edls-header',
                        ce('.edls-header-title', this.lmsg('unselectedTitle')),
                    ),
                    ce('.edls-box-wrap',
                        this._isSearchable ? this._getSearchControl('unselected') : null,
                        ce('.edls-box-body',
                            ce('ul.edls-list', { id: `${this._id}-unselected` }),
                            ce('.edls-list-empty', { style: 'display: none;' }, ce('span', this.lmsg('unselectedEmpty'))),
                            ce('.edls-ajax-loading', { style: 'display: none;' }, ce('span.ajax-loading', this.lmsg('loadingTitle'))),
                        ),
                    ),
                ),
                ce('.edls-control',
                    ce('.edls-control-wrap',
                        ce('.edls-control-body',
                            ce('.edls-control-text',
                                ce('.edls-control-text-none', this.lmsg('controlText-none')),
                                ce('.edls-control-text-first'),
                                ce('.edls-control-text-second'),
                            ),
                            ce('.edls-control-indicator', ce('i')),
                        ),
                    ),
                ),
                ce('.edls-box.edls-box-second',
                    ce('.edls-header',
                        ce('.edls-header-title', this.lmsg('selectedTitle')),
                    ),
                    ce('.edls-box-wrap',
                        this._isSearchable ? this._getSearchControl('selected') : null,
                        ce('.edls-box-body',
                            ce('ul.edls-list', { id: `${this._id}-selected` }),
                            ce('.edls-list-empty', { style: 'display: none;' }, ce('span', this.lmsg('selectedEmpty'))),
                            ce('.edls-ajax-loading', { style: 'display: none' }, ce('span.ajax-loading', this.lmsg('loadingTitle'))),
                        ),
                    ),
                ),
            ),
        );
        this._initList();
    }

    _initList() {
        super._initList();

        this._fixListZebra();
    }

    _fixListZebra() {
        [document.getElementById(`${this._id}-unselected`), document.getElementById(`${this._id}-selected`)].forEach(list => {
            let count = 0;
            list.querySelectorAll('.edls-item').forEach(el => {
                if (el.style.display !== 'none') {
                    el.classList.add(count % 2 ? 'odd' : 'even');
                    el.classList.remove(count % 2 ? 'even' : 'odd');
                    count++;
                }
            });
            list.parentNode.querySelector('.edls-list-empty').style.display = count ? 'none' : '';
        });
    }

    _getSearchControl(columnId) {
        return (
            ce('.edls-search',
                ce('span.edls-check', ce('input.checkbox', { type: 'checkbox', value: '' })),
                ce('span.edls-search-control',
                    ce('input.search-input.search-empty', {
                        type: 'text',
                        value: '',
                        autocomplete: 'off',
                        id: `${this._id}-search-${columnId}`,
                        onfocus: e => this.onFocus(e.target),
                        onblur: e => this.onBlur(e.target),
                        onkeyup: e => this.onToggleSearch(columnId, e.target.value),
                    }),
                    ce('input.search-button', {
                        type: 'image',
                        src: require('images/search-input.png'),
                        id: `${this._id}-search-button-${columnId}`,
                        onmousedown: () => this.onResetSearch(columnId),
                        onclick: e => e.preventDefault(),
                    }),
                ),
            )
        );
    }

    _toggleSearchButtonIcon(columnId, isActive) {
        const inputIcon = document.getElementById(`${this._id}-search-button-${columnId}`);
        inputIcon.classList[isActive ? 'add' : 'remove']('search-button-clear');
        inputIcon.src = isActive
            ? require('images/clear-search.png')
            : require('images/search-input.png');
    }

    onFocus(element) {
        element.classList.remove('search-empty');
    }

    _resetSearchControl(element) {
        element.value = '';
        element.classList.add('search-empty');
    }

    _initListItem(element, index, isSelected) {
        let container = null;
        let isHidden = false;
        if (isSelected) {
            container = document.getElementById(`${this._id}-selected`);
            isHidden = !element.selected;
        } else {
            container = document.getElementById(`${this._id}-unselected`);
            isHidden = element.selected;
        }

        render(
            container,
            ce(
                'li',
                {
                    id: `${this._id}-${index}-${isSelected ? 'selected' : 'unselected'}-item`,
                    class: 'edls-item',
                    style: isHidden ? 'display: none;' : null,
                    onclick: event => {
                        this._toggleItemSelection(index, isSelected, event);
                    },
                },
                ce(
                    'div',
                    { class: 'edls-item-wrap' },
                    ce(
                        'span',
                        { class: 'edls-check' },
                        ce('input', {
                            type: 'checkbox',
                            class: 'checkbox',
                            value: '',
                            onclick: event => {
                                event.stopPropagation();
                                this._toggleItemCheckbox(event.target, event.target.checked);
                            },
                        }),
                    ),
                    ce('span', { class: 'edls-text' }, this._getItemText(element)),
                ),
            ),
        );
    }

    _getItemText(element, highlight) {
        let itemIcons = '';
        if (element.icons && element.icons.length) {
            element.icons.each(({ src, title }) => {
                itemIcons += `<i class="icon"><img src="${Jsw.skinUrl}${src}" title="${title}"></i>`;
            });
            itemIcons += ' ';
        }
        let itemText = escapeHtml(element.title);
        if (highlight) {
            itemText = itemText.replace(highlight, `<b class="search-result-label">${highlight}</b>`);
        }

        return itemIcons + itemText;
    }

    onToggleSearch(columnId, searchString) {
        const isSelected = (columnId === 'selected');
        searchString = searchString.trim().toLowerCase();

        if (searchString.length) {
            this._toggleSearchButtonIcon(columnId, true);
            this._list.forEach((element, index) => {
                const item = document.getElementById(`${this._id}-${index}-${columnId}-item`);
                if (this._isSearchMatched(element, searchString)) {
                    item.querySelector('.edls-text').innerHTML = this._getItemText(element, searchString);
                    if (isSelected === element.selected) {
                        item.style.display = '';
                    }
                } else {
                    item.querySelector('.edls-text').innerHTML = this._getItemText(element);
                    const checkbox = item.querySelector('.edls-check input');
                    if (item.style.display !== 'none' && checkbox.checked) {
                        this._toggleItemCheckbox(checkbox, false);
                    }
                    item.style.display = 'none';
                }
            });
        } else {
            this._toggleSearchButtonIcon(columnId, false);
            this._showAllItems(columnId);
        }
    }

    _showAllItems(columnId) {
        const isSelected = (columnId === 'selected');
        this._list.forEach((element, index) => {
            const item = document.getElementById(`${this._id}-${index}-${columnId}-item`);
            item.querySelector('.edls-text').innerHTML = this._getItemText(element);
            if (isSelected === element.selected) {
                item.style.display = '';
            }
        });
    }

    _toggleItemSelection(index, isSelected, event, batchMode) {
        event.preventDefault();
        this._list[index].selected = !isSelected;
        this._updateElement(this._list[index]);
        document.getElementById(`${this._id}-${index}-${isSelected ? 'selected' : 'unselected'}-item`).style.display = 'none';
        document.getElementById(`${this._id}-${index}-${isSelected ? 'unselected' : 'selected'}-item`).style.display = '';
        this._toggleItemCheckbox(document.getElementById(`${this._id}-${index}-${isSelected ? 'selected' : 'unselected'}-item`).querySelector('.edls-check input'), false);
        if (!batchMode) {
            this._fixListZebra();
            this.onChange();
        }
    }

    _toggleItemCheckbox(checkbox, value) {
        checkbox.checked = value;

        const side = checkbox.closest('.edls-box-first') ? 'first' : 'second';
        const count = checkbox.closest(`.edls-box-${side}`).querySelectorAll('.edls-list .edls-check input:checked').length;
        if (value) {
            checkbox.closest('.edls-item').classList.add('selected');
        } else {
            checkbox.closest('.edls-item').classList.remove('selected');
            checkbox.closest('.edls-box').querySelector('.edls-search .checkbox').checked = false;
        }
        checkbox.closest('.edls').classList[count ? 'add' : 'remove'](`edls-selected-${side}`);
        checkbox.closest('.edls').querySelector(`.edls-control-text-${side}`).innerHTML =
            this.getLocale().formatMessage(`controlText-${side}`, { count });
    }

    _updateSelectControls() {
        // disable parent implementation
    }

    _addEvents() {
        const componentElement = this._componentElement;

        this._componentElement.querySelectorAll('.edls-box-wrap').forEach(el => {
            el.addEventListener('mouseover', event => {
                componentElement.classList.add('edls-over');
                componentElement.classList.add(event.target.closest('.edls-box-first') ? 'edls-over-first' : 'edls-over-second');
                componentElement.classList.remove(event.target.closest('.edls-box-first') ? 'edls-over-second' : 'edls-over-first');
            });
            el.addEventListener('mouseleave', () => {
                if (!componentElement.classList.contains('edls-selected-first') && !componentElement.classList.contains('edls-selected-second')) {
                    componentElement.classList.remove('edls-over');
                    componentElement.classList.remove('edls-over-first');
                    componentElement.classList.remove('edls-over-second');
                }
            });
        });

        this._componentElement.addEventListener('mouseleave', event => {
            event.target.classList.remove('edls-over');
            event.target.classList.remove('edls-over-first');
            event.target.classList.remove('edls-over-second');
        });

        this._componentElement.querySelectorAll('.edls-box .edls-search .edls-check input').forEach(element => {
            element.addEventListener('click', () => {
                const value = element.checked;
                element.closest('.edls-box').querySelectorAll('.edls-list .edls-check input').forEach(checkbox => {
                    if (value && checkbox.closest('.edls-item').style.display !== 'none') {
                        this._toggleItemCheckbox(checkbox, true);
                    } else {
                        this._toggleItemCheckbox(checkbox, false);
                    }
                });
            });
        });

        this._componentElement.querySelector('.edls-control-wrap').addEventListener('click', event => {
            let isSelected;
            if (componentElement.classList.contains('edls-over-first')) {
                isSelected = false;
            } else if (componentElement.classList.contains('edls-over-second')) {
                isSelected = true;
            } else {
                return;
            }

            this._list.each((element, index) => {
                if (element.selected === isSelected) {
                    const item = document.getElementById(`${this._id}-${index}-${isSelected ? 'selected' : 'unselected'}-item`);
                    if (item.classList.contains('selected')) {
                        this._toggleItemSelection(index, isSelected, event, true);
                    }
                }
            });
            this._fixListZebra();
            this.onChange();
        });
    }

    _onReloadCreate() {
        this._list = [];
        this._componentElement.querySelectorAll('.edls-box').forEach(listArea => {
            listArea.querySelector('ul').innerHTML = '';
            listArea.querySelector('.edls-ajax-loading').style.display = '';
            listArea.querySelector('.edls-list-empty').style.display = 'none';
        });
    }

    _onReloadComplete() {
        this._componentElement.querySelectorAll('.edls-box').forEach(listArea => {
            listArea.querySelector('.edls-ajax-loading').style.display = 'none';
        });
    }
}
