// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import StrengthLevels from './strengthLevels';

const scores = {
    [StrengthLevels.LEVEL_VERY_WEAK]: -1,
    [StrengthLevels.LEVEL_WEAK]: 15,
    [StrengthLevels.LEVEL_MEDIOCRE]: 24,
    [StrengthLevels.LEVEL_STRONG]: 34,
    [StrengthLevels.LEVEL_STRONGER]: 44,
};

const rules = {
    passwordTooShort(passwd) {
        return passwd.length < 5 ? -1 : 0;
    },

    passwordLength(passwd) {
        if (passwd.length < 5) {
            return 3;
        }
        if (passwd.length > 4 && passwd.length < 8) {
            return 6;
        }
        if (passwd.length > 7 && passwd.length < 16) {
            return 12;
        }
        return 18;
    },

    lettersLowerCase(passwd) {
        // [verified] at least one lower case letter
        return passwd.match(/[a-z]/) ? 1 : -1;
    },

    lettersUpperCase(passwd) {
        // [verified] at least one upper case letter
        return passwd.match(/[A-Z]/) ? 5 : -1;
    },

    numbers1(passwd) {
        // [verified] at least one number
        return passwd.match(/\d+/) ? 5 : -1;
    },

    numbers3(passwd) {
        // [verified] at least three numbers
        return passwd.match(/(.*[0-9].*[0-9].*[0-9])/) ? 5 : -1;
    },

    specialChar1(passwd) {
        // [verified] at least one special character
        return passwd.match(/[!@#$%^&*?_~]/) ? 5 : -1;
    },

    specialChar2(passwd) {
        // [verified] at least two special characters
        return passwd.match(/(.*[!@#$%^&*?_~].*[!@#$%^&*?_~])/) ? 5 : -1;
    },

    comboUpperAndLower(passwd) {
        // [verified] both upper and lower case
        return passwd.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/) ? 2 : -1;
    },

    comboLettersAndNumbers(passwd) {
        // [verified] both letters and numbers
        return passwd.match(/([a-zA-Z])/) && passwd.match(/([0-9])/) ? 2 : -1;
    },

    comboLettersNumbersSpecial(passwd) {
        // [verified] letters, numbers, and special characters
        return passwd.match(/([a-zA-Z0-9].*[!@#$%^&*?_~])|([!@#$%^&*?_~].*[a-zA-Z0-9])/) ? 2 : -1;
    },
};

const applyRules = passwd => {
    const suggestions = [];
    let sum = 0;
    Object.keys(rules).forEach(rule => {
        const mark = rules[rule](passwd);
        if (mark < 0) {
            suggestions.push(rule);
        } else {
            sum += mark;
        }
    });

    return {
        score: sum,
        suggestions,
    };
};

export default {
    estimatePassword: password => {
        const estimation = applyRules(password);

        const resultScore = Math.max(estimation.score, 0);
        let resultLevel = StrengthLevels.LEVEL_VERY_WEAK;

        for (const level in scores) {
            const score = scores[level];
            if (resultScore > score) {
                resultLevel = level;
            } else {
                break;
            }
        }

        return new Promise(resolve => {
            resolve({
                password,
                level: parseInt(resultLevel),
                percentage: (Math.min(resultScore, 42) / 42) * 100,
                suggestions: estimation.suggestions,
            });
        });
    },
};
