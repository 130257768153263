// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import ce from '../createElement';
import { Container } from '../container';
import { DropdownManager } from '../dropdown-manager';
import isRtl from '../isRtl';
import prepareUrl from '../prepareUrl';
import render from '../render';

const TARGET_TYPE_DROP_DOWN = 'dropDown';
const TARGET_TYPE_TABLE_ROW = 'tableRow';

class ListContextMenu extends Container {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._cls = 'popup-box popup-menu collapsed';
        this._list = this._getConfigParam('list');
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement.innerHTML = (
            '<table class="popup-wrapper" cellspacing="0"><tbody><tr><td class="popup-container">' +
                '<div class="c1"><div class="c2"><div class="c3"><div class="c4"><div class="c5">' +
                    '<div class="popup-content">' +
                        '<div class="popup-content-area"></div>' +
                    '</div>' +
                '</div></div></div></div></div>' +
            '</td></tr></tbody></table>'
        );
    }

    /**
     * Called on list redraw
     */
    onRedraw() {
        this._initRowStyles();
        this._initShowOnClick();
    }

    /**
     * Get list of actions for popup by row
     */
    _prefillPopup(row) {
        render(this._componentElement.querySelector('.popup-content-area'), (
            ce('ul', this._list.getItemActions(row).map(actionInfo => {
                if (actionInfo === 'separator') {
                    return ce('li.separator', ce('span'));
                }

                return ce('li',
                    ce('a', {
                        class: `sb-btn ${actionInfo.iconClass ? actionInfo.iconClass : 'sb-no-icon'}`,
                        href: actionInfo.href ? prepareUrl(actionInfo.href) : null,
                        target: actionInfo.newWindow ? '_blank' : null,
                        'data-button-key': actionInfo.name,
                        onclick: this._list._itemActions[actionInfo.name] ? event => {
                            event.preventDefault();
                            this._list._itemActions[actionInfo.name](row, event);
                        } : null,
                    }, ce('i',
                        ce('i',
                            ce('i',
                                ce('span', actionInfo.title || this._list.lmsg(`item-action-${actionInfo.name}`)),
                            ),
                        ),
                    )),
                );
            }))
        ), 'inner');
    }

    /**
     * Show prefilled popup on context menu button click
     */
    _togglePopupNearButton(button) {
        document.getElementById(this._list._tableId).querySelectorAll('tr.odd,tr.even').forEach(el => {
            el.classList.remove('row-over');
        });
        this._componentElement.classList.add('collapsed');

        if (!button.closest('.btn-group').classList.contains('btn-group-open')) {
            document.querySelectorAll('.btn-list-menu').forEach(el => {
                el.closest('.btn-group').classList.remove('btn-group-open');
            });

            this._componentElement.classList.remove('collapsed');
            button.closest('tr').classList.add('row-over');
        }

        button.closest('.btn-group').classList.toggle('btn-group-open');

        const scrollOffsets = document.viewport.getScrollOffsets();
        const buttonPosition = Element.viewportOffset(button);
        buttonPosition.left += scrollOffsets.left;
        buttonPosition.top += scrollOffsets.top;

        const buttonWidth = Element.getWidth(button);
        const buttonHeight = Element.getHeight(button);

        if (!this._componentElement.classList.contains('collapsed')) {
            this._setPosition(
                buttonPosition.left,
                buttonPosition.top + buttonHeight,
                buttonPosition.left + buttonWidth,
                buttonPosition.top,
            );
        }
    }

    _initRowStyles() {
        document.getElementById(this._list._tableId).querySelectorAll('tr.odd,tr.even').forEach(el => {
            const button = el.querySelector('.dropdown-toggle');
            if (!button) {
                return;
            }

            const row = this._list.getItemById(button.dataset.rowId);
            if (!row || !this._list.getItemActions(row).length) {
                return;
            }

            el.classList.add('list-context-actions');
        });
    }

    /**
     * Hide popup and remove selection from row
     */
    _hidePopup() {
        this._componentElement.classList.add('collapsed');
        this.targetData.rowEl.querySelector('.btn-group').classList.remove('btn-group-open');
        this.targetData.rowEl.classList.remove('row-over');
    }

    _openPopup(event) {
        this._prefillPopup(this.targetData.rowData);
        if (this.targetType === TARGET_TYPE_TABLE_ROW) {
            this.targetData.rowEl.classList.add('row-over');
            this._componentElement.classList.remove('collapsed');
            this._setPosition(event.clientX + (window.scrollX || window.pageXOffset), event.clientY + (window.scrollY || window.pageYOffset));
        } else {
            this._togglePopupNearButton(this.targetData.dropDownEl);
        }
    }

    _isListContextEvent({ target }) {
        return !target.closest('input,a') && (target.closest('.list-menu') || target.closest('[data-row-id]'));
    }

    _getTargetData(target) {
        const rowEl = target.closest('tr');
        let dropDownEl;

        if (this.targetType === TARGET_TYPE_DROP_DOWN) {
            dropDownEl = target.closest('.btn-list-menu');
        } else if (this.targetType === TARGET_TYPE_TABLE_ROW) {
            dropDownEl = rowEl.querySelector('.dropdown-toggle');
        }
        if (!dropDownEl) {
            return null;
        }

        const rowData = this._list.getItemById(dropDownEl.dataset.rowId);
        if (rowData && this._list.getItemActions(rowData).length) {
            return {
                rowData,
                rowEl,
                dropDownEl,
            };
        }

        return null;
    }

    _getTargetType(target) {
        if (target.closest('.list-menu')) {
            return TARGET_TYPE_DROP_DOWN;
        }

        if (target.closest('[data-row-id]')) {
            return TARGET_TYPE_TABLE_ROW;
        }

        return null;
    }

    _open(event) {
        this.targetType = this._getTargetType(event.target);
        this.targetData = this._getTargetData(event.target);

        if (!this.targetData) {
            return;
        }

        this._openPopup(event);
    }

    _hide(event) {
        if (!this.targetData) {
            return;
        }

        this._hidePopup(event);

        if (this._isListContextEvent(event) && this._isImmediatelyOpening(event)) {
            this._open(event);
        }
    }

    _isOpened() {
        return (
            (
                this.targetData
                && this.targetData.dropDownEl
                && this.targetData.dropDownEl.closest('.btn-group').classList.contains('btn-group-open')
            )
            || !this._componentElement.classList.contains('collapsed')
        );
    }

    _isImmediatelyOpening(event) {
        const targetType = this._getTargetType(event.target);
        const targetData = this._getTargetData(event.target);

        return (targetType && targetData
            && ((targetType === TARGET_TYPE_DROP_DOWN && this.targetType === TARGET_TYPE_TABLE_ROW)
                || (targetType === TARGET_TYPE_DROP_DOWN
                && targetData.rowEl.dataset.rowId !== this.targetData.rowEl.dataset.rowId)
            )
        );
    }

    /**
     * Show popup on click row or dropdown button
     */
    _initShowOnClick() {
        DropdownManager.register(
            document.getElementById(this._list._tableId),
            this._isOpened.bind(this),
            this._open.bind(this),
            this._hide.bind(this),
            this._isListContextEvent.bind(this),
        );
    }

    _setPosition(x, y, altX, altY) {
        altX = altX || x;
        altY = altY || y;

        const scrollOffsets = document.viewport.getScrollOffsets();

        const windowWidth = Element.getWidth(document.body);
        const popupWidth = Element.getWidth(this._componentElement);
        if (isRtl()) {
            if ((altX - popupWidth - scrollOffsets.left) > 0) {
                x = altX - popupWidth;
            }
        } else if ((x + popupWidth - scrollOffsets.left) > windowWidth) {
            x = altX - popupWidth;
        }

        const windowHeight = Element.getHeight(document.body);
        const popupHeight = Element.getHeight(this._componentElement);
        const popupMarginTop = parseInt(Element.getStyle(this._componentElement, 'marginTop'));
        if ((y + popupHeight + popupMarginTop - scrollOffsets.top) > windowHeight) {
            y = altY - popupHeight - (2 * popupMarginTop);
        }

        this._componentElement.style.right = 'auto';
        this._componentElement.style.left = `${x}px`;
        this._componentElement.style.top = `${y}px`;
    }
}

export default ListContextMenu;
